import React from "react";
import YouTube from "react-youtube";
import ProjectModal from "../../component/ProjectModal";
import { useMediaQuery } from "react-responsive";

import project from "../../assets/project.png";
import SectionTitle from "../../component/SectionTtitle/SectionTitle";
import founderslive from "../../assets/partners/founderslive.png";
import kosme from "../../assets/partners/kosme.png";
import kypsi from "../../assets/partners/kypsi.png";
import WTIA from "../../assets/partners/WTIA.png";

const Project = () => {
  const [ModalVisible, setModalVisible] = React.useState(false);
  const [value, setValue] = React.useState(0);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onModalClick = value => {
    setModalVisible(true);
    setValue(value);
  };
  const onModalClose = () => {
    setModalVisible(false);
    setValue(0);
  };

  const LargeScreen = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const XXLargeScreen = useMediaQuery({
    query: "(min-width: 1535px)",
  });

  const RenderContent = ({ title, date, click }) => {
    return (
      <div
        className="col-span-4 2xl:col-span-1 flex flex-col bg-white items-center justify-center rounded-2xl shadow-md relative px-3 py-12"
        style={{ minHeight: 320 }}
      >
        <span className="text-lg font-bold text-center ">{title}</span>
        <p className="text-gray-600">{date}</p>
        <div
          onClick={() => click(1)}
          className="bg-gray-400 px-4 py-1 mt-3 rounded-full cursor-pointer hover:bg-blue-800 text-white"
        >
          <p className=" text-xs">more</p>
        </div>
      </div>
    );
  };
  const RenderPartners = ({ title, content, link, image }) => {
    return (
      <div
        className="col-span-4 2xl:col-span-1 flex flex-col bg-white items-center justify-center rounded-2xl shadow-md relative px-12 py-12"
        style={{ minHeight: 320 }}
      >
        <img
          src={image}
          alt={title}
          style={{ width: 150, objectFit: "contain" }}
        />
        <span className="text-lg font-bold text-center mt-3">{title}</span>
        <p className="text-black ">{content}</p>
        <div className="bg-gray-400 px-4 py-1 mt-3 rounded-full cursor-pointer hover:bg-blue-800 text-white">
          <a href={link} target="_blank" rel="noreferrer">
            <p className=" text-xs">LINK</p>
          </a>
        </div>
      </div>
    );
  };
  return (
    <div className="grid grid-cols-4 gap-3 lg:gap-12  w-full">
      <SectionTitle image={project} title="Partnering with KOSME and WTIA" />

      {/* 2 */}
      <div className="col-span-4 flex flex-col items-center justify-center mt-2 w-full p-3 2">
        <div className="px-6 lg:px-12 flex flex-col items-start lg:items-center justify-center">
          <p className="text-lg font-bold text-black text-justify">
            OUR PARTNERS
          </p>
          <p className="text-md   mt-3 self-start text-black text-justify">
            Our connections and partnerships allow us to provide our clients
            with a range of services to fit any business need. Seattle Basecamp
            has a longstanding relationship with KOSME, the Korean government’s
            startup center. As a valued partner, we are often tasked with
            helping startups build a presence in the U.S. market. Our staff and
            our team of expert consultants represent a wide variety of major
            companies and up-and-coming startups from the Seattle Area. We
            partner closely with the highly influential WTIA, the Washington
            Technology Industry Association, to connect with the top talent in
            the tech industry. Our partnerships extend beyond just technology
            companies to retail and marketing as well. We also work with KYPSI,
            a B2B marketing agency.
          </p>
        </div>
        <div className="col-span-4 grid grid-cols-4 gap-3 mt-12 w-full">
          {/* 1 */}
          <RenderPartners
            title="Korea SMEs and Startups Agency"
            content="A non-profit, government-funded organization established to implement government policies and programs
            for the sound growth and development of Korean SMEs."
            link="http://www.kosmes.or.kr/sbc/SH/EHP/SHEHP001M0.do"
            image={kosme}
          />
          <RenderPartners
            title="Washington Technology Industry Association"
            content="An influential co-op of 1,000 tech companies large and small. We are pragmatic partners with education and
            government leaders building a better world."
            link="https://www.washingtontechnology.org/"
            image={WTIA}
          />

          {/* <RenderPartners
            title="FOUNDERS LIVE"
            content="The global venue and network that gives tens of thousands of modern entrepreneurs the opportunity to earn
            global recognition, gain new business opportunities, connect with other world class entrepreneurs, access
            important growth capital, and truly become rockstars."
            link="https://www.founderslive.com/"
            image={founderslive}
          /> */}
          <RenderPartners
            title="KYPSI"
            content="A B2B marketing agency that helps startups to medium-size companies with done-for-you business
development using top sales executives."
            link="http://www.kypsi.com/"
            image={kypsi}
          />
        </div>
      </div>
      <div className="col-span-4 flex flex-col items-center">
        <p className="text-lg font-bold text-black text-justify">
          OUR PROJECTS
        </p>
        <p className="text-md   mt-3  text-black text-justify">
          Here are some of our past projects
        </p>
      </div>
      <div className="col-span-4 grid grid-cols-4 gap-3 m-3">
        {/* 1 */}
        <RenderContent
          title={"LG GLOBAL X PROGRAM"}
          date="September 2019"
          click={() => onModalClick(1)}
        />
        <RenderContent
          title={"KOREAN STARTUP CENTER 4-WEEK ACCELERATOR PROGRAM"}
          date="January 2020"
          click={() => onModalClick(2)}
        />

        <RenderContent
          title="KOREAN STARTUP CENTER 6-WEEK ACCELERATOR PROGRAM (VIRTUAL)"
          date="September 2020"
          click={() => onModalClick(3)}
        />
        <RenderContent
          title="LG 4-WEEK MBA GLOBAL HACKATHON"
          date="October 2020"
          click={() => onModalClick(4)}
        />
      </div>
      {ModalVisible && (
        <ProjectModal
          visible={ModalVisible}
          onClose={() => onModalClose()}
          closable={true}
          maskClosable={true}
          title={
            value === 1
              ? "LG GLOBAL X PROGRAM"
              : value === 2
              ? "KOREAN STARTUP CENTER 4-WEEK ACCELERATOR PROGRAM"
              : value === 3
              ? "KOREAN STARTUP CENTER 6-WEEK ACCELERATOR PROGRAM (VIRTUAL)"
              : value === 4
              ? "LG 4-WEEK MBA GLOBAL HACKATHON"
              : null
          }
          width={XXLargeScreen ? "50vw" : "90vw"}
        >
          {value === 1 && (
            <div className="flex flex-col items-center ">
              <div className="p-6">
                <p className={"mt-1"}>
                  Finalists from LG’s internal hackathon were brought to Seattle
                  to participate in 2-week incubation program, undertaking
                  market validation for their product. For this program, we
                  created shell companies for each group, booked them local
                  office space, and aided them in conducting market surveys,
                  customer interviews, competitor research, street demo and
                  networking events, and hiring temporary local talent to assist
                  them. We also conducted tours of Seattle tech companies,
                  luncheons with employees, and company strategy seminars with
                  corporate partners. The program culminated in a demonstration
                  and presentation day.
                </p>
                <p className="mt-1">
                  The final products can be seen here:{" "}
                  <strong>DoggoMaster</strong> and <strong>MyBabyDaily</strong>
                </p>
                <div className="flex flex-col items-center w-full mt-3 ">
                  <div className="mt-3 w-full lg:w-1/2 flex flex-col items-center ">
                    <YouTube
                      videoId="TZob7l4zvHk"
                      opts={{
                        width: XXLargeScreen || LargeScreen ? 640 : "100%",
                        height: XXLargeScreen || LargeScreen ? 360 : 240,
                        playerVars: { autoplay: 0 },
                      }}
                    />
                  </div>
                  <div className="mt-3  w-full lg:w-1/2 flex flex-col items-center">
                    <YouTube
                      videoId="-h3thQ4TYn4"
                      opts={{
                        width: XXLargeScreen || LargeScreen ? 640 : "100%",
                        height: XXLargeScreen || LargeScreen ? 360 : 240,
                        playerVars: { autoplay: 0 },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {value === 2 && (
            <div className="flex flex-col items-center ">
              <div className="p-6">
                <p className={"mt-2"}>
                  The Korean Startup Center is a non-profit branch of the Korean
                  government that operates worldwide. They partnered with
                  SeattleBase to run an incubator program for 7 startups at
                  various stages of growth. The companies were divided into 2
                  groups: one to focus on product development and one to secure
                  funding from US investors. The teams spent 4 weeks in Seattle
                  and were paired with experts to help them hone and improve
                  their ideas and present them to investors. We guided each team
                  through the process of establishing themselves as legal U.S.
                  entities, building and practicing pitch decks, and hire local
                  partners. Information on the entire event can be found at this{" "}
                  <a
                    className="text-blue-500"
                    href="https://firebasestorage.googleapis.com/v0/b/seattlebase-3c827.appspot.com/o/resume%2Fproject%2FKSC%201.pdf?alt=media"
                    target="_blank"
                    rel="noreferrer"
                    alt="ksc1"
                  >
                    link
                  </a>
                </p>
                {/* <p className="mt-1">
                  Omorobot: an automated robotic cart for warehouse use
                </p>
                <ul className="px-6">
                  <li className="list-disc">
                    market validation through different prospects meetings in
                    several segments
                  </li>
                  <li className="list-disc">
                    meeting with Amazon Warehouse and Garden Center Canada, UPS
                  </li>
                </ul>
                <p className="mt-1">
                  Pnafore: a baking supply company and online marketplace
                </p>
                <ul className="px-6">
                  <li className="list-disc">
                    Set up onsite meeting with investor for in-depth review
                  </li>
                  <li className="list-disc">
                    Reach out to Prospect new investors in Seattle and Silicon
                    valley
                  </li>
                  <li className="list-disc">
                    Apply startup programs including Y Combinator, Snapchat lab,
                    New Chip program.
                  </li>
                </ul>
                <p className="mt-1">
                  Omorobot: an automated robotic cart for warehouse use
                </p>
                <ul className="px-6">
                  <li className="list-disc">
                    market validation through different prospects meetings in
                    several segments
                  </li>
                  <li className="list-disc">
                    meeting with Amazon Warehouse and Garden Center Canada, UPS
                  </li>
                </ul>
                <p className="mt-1">
                  H-Robotics: a company that develops tele-rehab robotic
                  healthcare products
                </p>
                <ul className="px-6">
                  <li className="list-disc">
                    Consult with Rehab medicine experts from UW Medicine and
                    Johns Hopkins
                  </li>
                  <li className="list-disc">
                    Connect with local distributors including local medical
                    device stores
                  </li>
                  <li className="list-disc">
                    Consult formation of Regioanl Office and hire head of US
                    marketing.
                  </li>
                </ul> */}
              </div>
            </div>
          )}
          {value === 3 && (
            <div className="flex flex-col items-center ">
              <div className="p-6">
                <p className={"mt-2"}>
                  This event followed the same format as the January program,
                  but was entirely virtual due to the COVID-19 pandemic.
                  SeattleBase chose 11 startups to put through our program.
                  Information on the entire event can be found at this{" "}
                  <a
                    className="text-blue-500"
                    href="https://firebasestorage.googleapis.com/v0/b/seattlebase-3c827.appspot.com/o/resume%2Fproject%2FKSC%202.pdf?alt=media"
                    target="_blank"
                    rel="noreferrer"
                    alt="ksc2"
                  >
                    link.
                  </a>
                </p>

                {/* <p className="mt-1">
                  Morai: True-tolife Autonomous vechicle driving simulator
                </p> */}
                {/* <ul className="px-6">
                  <li className="list-disc">Accepted Nvidia Startup program</li>
                  <li className="list-disc">
                    In talk with Waymo, Lyft, and University of Washington for
                    partership
                  </li>
                  <li className="list-disc">
                    Meeting with Beoing, Mitsubishi, Washington State
                    University, Washington State Autonomous Work Group
                  </li>
                </ul> */}
                {/* <p className="mt-1">
                  GoodByeCar: Electic vechicle Battery upcycling business
                </p>
                <ul className="px-6">
                  <li className="list-disc">Set up a USA company</li>
                  <li className="list-disc">
                    Ran a Kickstarter campagin succeffuly
                  </li>
                  <li className="list-disc">
                    In talk with Militarries Batteries for partnership
                  </li>
                </ul> */}
              </div>
            </div>
          )}
          {value === 4 && (
            <div className="flex flex-col items-center ">
              <div className="p-6">
                <p className={"mt-2"}>
                  This event was online-only due to the ongoing pandemic.
                  SeattleBase organized and ran a virtual hackathon for 18 teams
                  of 120 LG employees. We led daily seminars with the
                  participants and paired each team with a series of experts in
                  fields like product development, user experience design, and
                  market research. The teams were taught user-centered design
                  principles and UX research methods to better understand
                  product design from the perspective of the customer.
                </p>
                <p className="mt-1">
                  The{" "}
                  <a
                    className="text-blue-500"
                    href="https://firebasestorage.googleapis.com/v0/b/seattlebase-3c827.appspot.com/o/resume%2Fproject%2FLG%20MBA%20GLOBAL%20HACKATHON.pdf?alt=media"
                    target="_blank"
                    rel="noreferrer"
                    alt="LG MBA GLOBAL HACKATHON"
                  >
                    link
                  </a>{" "}
                  to the final deliverables can be found here.
                </p>
              </div>
            </div>
          )}
        </ProjectModal>
      )}
    </div>
  );
};

export default Project;
