import React from "react";
import { Link } from "react-router-dom";
import SpaceNeedle from "../../assets/spaceneedle.png";
import meeting from "../../assets/meeting.png";
import stickynote from "../../assets/stickynote.png";
import shakinghands from "../../assets/shakinghands.png";
import seattle from "../../assets/seattle.png";
import Wrapper from "../../component/Warpper";
import companies from "../../component/Compaines";
const Home = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const RenderContent = props => {
    return (
      <div
        className={`mt-3 lg:mt-5 mx-3  lg:mx-0 flex flex-col bg-white items-center justify-between lg:h-96 ${
          props.type === "right" ? "lg:flex-row-reverse" : "lg:flex-row"
        }  lg:items-center lg:justify-between rounded-2xl overflow-hidden shadow-md`}
      >
        {props.children}
      </div>
    );
  };

  return (
    <Wrapper>
      <div className="relative col-span-4 flex flex-col bg-white items-center justify-center lg:rounded-2xl lg:mt-3 overflow-hidden shadow-md ">
        <div className="w-full aspect-w-16 aspect-h-9 ">
          <img
            alt="seattle"
            src={SpaceNeedle}
            className="w-full bg-center bg-cover bg-no-repeat object-contain"
          />
        </div>
        <div className="absolute p-6 lg:p-12 flex flex-col items-start lg:items-center justify-center">
          <p className="text-md lg:text-2xl text-white font-bold ">
            At SeattleBase, we provide one-of-a-kind strategic business services
            to Korean companies looking to enter the U.S. and global markets.
          </p>
          <Link
            to="/partners"
            className="outline-none right-1 bg-blue-800 text-white px-4 lg:px-6 py-2 lg:py-4 rounded-full flex flex-row items-center justify-between text-xs lg:text-md mt-6"
          >
            LEARN ABOUT OUR PROJECT
            {/* <FiArrowRight className="text-md ml-3" /> */}
          </Link>
        </div>
      </div>
      {/* 1 */}

      <RenderContent>
        <div className="w-full lg:w-1/2 bg-white flex flex-col items-center justify-center overflow-hidden">
          <img
            src={meeting}
            alt="meeting"
            className="w-full lg:h-96  object-cover"
          />
        </div>
        <div className="lg:w-1/2  h-full flex flex-col items-start justify-between p-6 ">
          <span className="text-md lg:text-2xl font-bold text-blue-800">
            WHO WE ARE
          </span>
          <div>
            <p className="text-sm mt-1">
              We are a team of experts with decades of experience in both the
              U.S. and Korean markets. We are passionate about helping to bridge
              the gap between the two.
            </p>
            <p className="text-sm mt-1">
              We represent a diverse set of skills from business development to
              venture-capital investment to engineering, and we believe in using
              those skills to help our clients level up.
            </p>
          </div>
          <Link
            to="/about"
            className="outline-none right-1 bg-blue-800  text-white px-4 py-2 rounded-full flex flex-row items-center justify-between text-xs mt-3"
          >
            LEARN MORE
            {/* <FiArrowRight className="text-md ml-3" /> */}
          </Link>
        </div>
      </RenderContent>

      {/* 2 */}
      <RenderContent type="right">
        <div className=" lg:w-1/2  bg-white flex flex-col items-center justify-center">
          <img
            src={stickynote}
            alt="stickynote"
            className="w-full lg:h-96  object-cover"
          />
        </div>
        <div className="lg:w-1/2 h-full mt-3 flex flex-col items-start justify-between p-6">
          <span className="text-md lg:text-2xl font-bold text-blue-800">
            WHAT WE DO
          </span>
          <p className="text-sm mt-1">
            Whether you represent a brand new startup or one of the largest
            corporations in the world, we provide a bespoke experience catered
            to your needs. Some of our offerings include:
          </p>
          <ul className="text-sm mt-1 list-disc px-6">
            <li className="">Expert product & market-fit consultations</li>
            <li>Investment preparation & networking</li>
            <li>U.S. entity establishment and operations</li>
            <li>Corporate strategy seminars</li>
            <li>Human-capital development training</li>
          </ul>
          <Link
            to="/services"
            className="outline-none right-1 bg-blue-800  text-white px-4 py-2 rounded-full flex flex-row items-center justify-between text-xs mt-6"
          >
            LEARN MORE
            {/* <FiArrowRight className="text-md ml-3" /> */}
          </Link>
        </div>
      </RenderContent>
      {/* 3 */}
      <RenderContent>
        <div className="lg:w-1/2  bg-white flex flex-col items-center justify-center">
          <img
            src={seattle}
            alt="seattle"
            className="w-full lg:h-96  object-cover"
          />
        </div>
        <div className="  lg:w-1/2 mt-3 flex flex-col items-start p-6">
          <span className="text-md lg:text-2xl font-bold text-blue-800">
            WHY SEATTLE
          </span>
          <p className="text-sm mt-1">
            Seattle is the thriving, dynamic home of many of the global leaders
            in the technology industry. Famously the birthplace of Microsoft and
            Amazon, corporate giants like T-Mobile, Starbucks, Costco, and
            Expedia are also headquartered here. Every leader in the industry
            has a growing presence in Seattle, including Facebook, Apple,
            Google, and a host of others.
          </p>
          <p className="text-sm mt-1">
            This innovative environment means that Seattle is full of some of
            the brightest talents in the field, bringing a wealth of experience
            in business consulting, market research, investment know-how, and
            engineering savvy. It is this remarkable human capital that truly
            makes Seattle special.
          </p>
        </div>
      </RenderContent>
      {/* 4 */}
      <RenderContent type="right">
        <div className="w-full lg:w-1/2 bg-white flex flex-col items-center justify-center">
          <img
            src={shakinghands}
            alt="shakinghands"
            className="w-full lg:h-96  object-cover"
          />
        </div>
        <div className="lg:w-1/2 mt-3 flex flex-col items-start p-6">
          <span className="text-md lg:text-2xl font-bold text-blue-800">
            PARTNERS
          </span>
          <p className="text-sm mt-1">
            Our strength is in our partnerships, both in Seattle and in Korea.
          </p>
          <div className="mt-3 flex flex-row items-start justify-start w-full h-full flex-wrap ">
            {companies.map((item, index) => (
              <div
                key={index}
                style={{ marginRight: 10, marginBottom: 3 }}
                className="flex flex-col items-center justify-center "
              >
                <img
                  alt={`${item.name}`}
                  src={item.image}
                  style={{ width: 80, height: 30, objectFit: "contain" }}
                />
              </div>
            ))}
          </div>
          <Link
            to="/partners"
            className="outline-none right-1 bg-blue-800  text-white px-4 py-2 rounded-full flex flex-row items-center justify-between text-xs mt-6"
          >
            VIEW OUR PAST PROJECT
            {/* <FiArrowRight className="text-md ml-3" /> */}
          </Link>
        </div>
      </RenderContent>
    </Wrapper>
  );
};

export default Home;
