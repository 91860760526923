import React from "react";

import { AiOutlineCloseCircle } from "react-icons/ai";
function ProjectModal({
  onClose,
  maskClosable,
  visible,
  children,
  width,
  title,
}) {
  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  React.useEffect(() => {
    document.body.style.cssText = ` top:0px; width:'100%'`;
    document.body.style.overflow = "hidden";
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`;
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <div>
      <div
        className={` box-border ${
          visible ? "block" : "none"
        } fixed top-0 left-0 right-0 bottom-0 z-30 bg-black bg-opacity-60`}
        visible={visible}
      />
      <div
        className={`box-border ${
          visible ? "block" : "none"
        }  fixed  top-0 right-0 bottom-0 left-0 z-40 outline-none overflow-auto  h-full  m-3  `}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
      >
        <div
          tabIndex="0"
          className="fixed right-0 bottom-0 left-0  shadow-xl bg-white top-5  m-auto overflow-hidden "
          style={{ width, height: "80vh" }}
        >
          <div className="sticky bg-blue-800 flex flex-row  justify-between  items-stretch py-3 px-6">
            <span className="font-bold text-white">{title}</span>
            <div onClick={() => onClose()}>
              <AiOutlineCloseCircle className="text-2xl top-3 right-3  text-white" />
            </div>
          </div>
          <div className="outline-none overflow-auto h-full w-full pb-20">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectModal;
