import React from "react";
// import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "firebase";

const ResetPassWord = () => {
  // let history = useHistory();
  const [email, setEmail] = React.useState("");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const passwordReset = async email => {
    await firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(result => toast.success("Password reset email sent"))
      .catch(error => {
        toast.error(error.message);
      });
  };
  return (
    <div className="w-full p-3 ">
      {/* 1 */}
      <div className="mt-3 w-full flex flex-col items-center justify-between p-6 bg-white rounded-xl">
        <span className="text-md lg:text-2xl font-bold text-blue-800">
          PASSWORD RESET
        </span>
        <div className="w-full lg:w-1/3 mt-3">
          <div className="flex flex-col items-start  w-full">
            <label>
              <span className="text-gray-500">EMAIL</span>
            </label>
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="px-3 bg-gray-200 py-1 rounded-md w-full"
            />
          </div>
        </div>

        <button
          className="bg-blue-800 px-4 py-1 rounded-full mt-4"
          onClick={() => passwordReset(email)}
        >
          <p className="text-md text-white">SEND</p>
        </button>
      </div>
    </div>
  );
};

export default ResetPassWord;
