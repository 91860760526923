import React from "react";
// import ReactExport from "react-data-export";
import { useHistory } from "react-router-dom";
import { firestore } from "../../firebase";
import firebase from "firebase";
import moment from "moment";
import XLSX from "xlsx";

const Admin = () => {
  let history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [contactData, setContactData] = React.useState([]);
  const [lastSnap, setLastSnap] = React.useState();
  const [ismore, setIsMore] = React.useState(true);

  const clickDown = () => {
    let today = moment().format("YYYY-MM-DD");
    const worksheet = XLSX.utils.json_to_sheet(contactData); // excel sheet하단의 worksheet에 해당
    const new_workbook = XLSX.utils.book_new(); // excel 파일에 해당
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS"); // excelsheet를 excel파일에 넣음
    XLSX.writeFile(new_workbook, today + ".xlsx");
  };
  const clickmore = async () => {
    if (lastSnap) {
      console.log("more", lastSnap.data());
      let next = firestore
        .collection("database")
        .orderBy("updateAt", "desc")
        .startAfter(lastSnap.data().updateAt)
        .limit(10);
      let nextSnapshot = await next.get();
      let data = nextSnapshot.docs;
      const last = data[data.length - 1];
      console.log("more", data);
      let contactArray = [];
      if (data.length < 10) {
        setLastSnap();
        setIsMore(false);
      } else {
        setLastSnap(last);
        setIsMore(true);
      }
      const delayedLog = ({ item, index }) => {
        let changeData = item.data();
        console.log(changeData);
        contactArray.push({
          ...changeData,
          index: index + 1,
          updateAt: changeData.updateAt
            ? moment(changeData.updateAt.seconds * 1000).format("YYYY/MM/DD")
            : null,
        });
      };
      async function processArray(data) {
        for (const [index, item] of data.entries()) {
          await delayedLog({ item, index });
        }
        console.log("Done!", contactArray);
        setContactData([...contactData, ...contactArray]);
      }
      processArray(data);
    } else {
      console.log("없음");
    }
  };
  const getDb = async () => {
    const first = firestore
      .collection("database")
      .orderBy("updateAt", "desc")
      .limit(10);

    const contactdb = await first.get();
    console.log(contactdb.docs);

    const last = contactdb.docs[contactdb.docs.length - 1];
    setLastSnap(last);
    let contactArray = [];

    let data = contactdb.docs;

    const delayedLog = ({ item, index }) => {
      let changeData = item.data();
      console.log(changeData);
      contactArray.push({
        ...changeData,
        index: index + 1,
        updateAt: changeData.updateAt
          ? moment(changeData.updateAt.seconds * 1000).format("YYYY/MM/DD")
          : null,
      });
    };
    async function processArray(data) {
      for (const [index, item] of data.entries()) {
        await delayedLog({ item, index });
      }
      console.log("Done!", contactArray);
      setContactData(contactArray);
    }
    processArray(data);
  };
  React.useEffect(() => {
    getDb();
  }, []);
  const signOut = async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        history.push("/");
      })
      .catch(error => {
        // An error happened.
      });
  };
  return (
    <div className="w-full m-3 ">
      <div
        style={{ minHeight: 600 }}
        className="w-full flex flex-col  bg-white items-center lg:items-start  justify-start rounded-2xl overflow-hidden shadow-md "
      >
        <div className="p-6 flex flex-row items-center justify-between w-full">
          <span className="text-md lg:text-2xl font-bold text-blue-800">
            ADMIN
          </span>

          <div
            onClick={() => signOut()}
            className="px-4 py-1 bg-gray-300 rounded-full text-white cursor-pointer text-xs"
          >
            <p>Sign Out</p>
          </div>
        </div>
        <button
          onClick={() => clickDown()}
          className="ml-3 px-4 py-1 bg-gray-300 rounded-full text-black text-xs"
        >
          EXPORT
        </button>

        <div className="w-full mt-1 p-3">
          {contactData.length > 0 ? (
            <table className="table-fixed grid grid-cols-12 ">
              <thead className="col-span-12 bg-gray-200">
                <tr className="grid grid-cols-12 gap-3 border-b border-gray-300 w-full font-thin py-2 px-1">
                  {/* <th className="col-span-1 text-center font-normal text-sm">
                    index
                  </th> */}
                  <th className="col-span-2 lg:col-span-1 text-left font-normal text-sm">
                    type
                  </th>
                  <th className="col-span-2 lg:col-span-1 text-left font-normal text-sm">
                    created
                  </th>
                  <th className="col-span-2 lg:col-span-2 text-left font-normal text-sm">
                    name
                  </th>
                  <th className="col-span-2 lg:col-span-2 text-left font-normal text-sm">
                    email
                  </th>
                  <th className="lg:col-span-3 text-left font-normal hidden lg:block text-sm">
                    subject
                  </th>
                </tr>
              </thead>
              <tbody className="col-span-12 ">
                {contactData.map((item, index) => (
                  <tr
                    key={index}
                    className="grid grid-cols-12 gap-3 w-full border-b border-gray-300 py-2"
                  >
                    {/* <td className="col-span-1 lg:col-span-1 text-center text-xs  flex flex-col items-center justify-start whitespace-nowrap">
                      {item.index}
                    </td> */}
                    <td className="col-span-2 lg:col-span-1 text-center text-xs flex flex-col items-start justify-start whitespace-nowrap ">
                      <p
                        className={`text-center rounded-full text-xs ${
                          item.type === "contact"
                            ? " text-blue-800"
                            : " text-yellow-500"
                        }`}
                      >
                        {item.type.toUpperCase()}
                      </p>
                    </td>
                    <td className="col-span-2 lg:col-span-1 text-center text-xs  flex flex-col items-start justify-start whitespace-nowrap ">
                      {item.updateAt ? item.updateAt : null}
                    </td>
                    <td className="col-span-2 lg:col-span-2 text-center text-xs  flex flex-col items-start justify-start whitespace-nowrap">
                      {item.name}
                    </td>
                    <td className="col-span-4 lg:col-span-2 text-center text-xs flex flex-col items-start justify-start whitespace-nowrap ">
                      {item.email}
                    </td>
                    <td className="lg:col-span-4 text-center text-xs   flex-col items-start justify-start hidden lg:flex">
                      <p className="w-full text-left whitespace-wrap overflow-ellipsis truncate">
                        {item.subject}
                      </p>
                    </td>
                    <td className="col-span-1 text-center text-xs  flex flex-col items-start justify-start ">
                      <p className="w-full text-left ">more</p>
                    </td>
                    {/* <td className="col-span-3 text-center  text-xs  whitespace-nowrap overflow-hidden">
                      <p className="w-32 whitespace-nowrap overflow-ellipsis">
                        {item.message}
                      </p>
                    </td>
                    {item.linkedin ? (
                      <td className="col-span-1 text-center  text-xs whitespace-nowrap overflow-hidden ">
                        {item.linkedin}
                      </td>
                    ) : (
                      <td className="col-span-1 text-center  text-xs whitespace-nowrap overflow-hidden ">
                        -
                      </td>
                    )}
                    {item.resume ? (
                      <td className="col-span-1 text-center  text-xs whitespace-nowrap overflow-hidden ">
                        <a
                          href={item.resume}
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-800 underline whitespace-nowrap overflow-hidden "
                        >
                          down
                        </a>
                      </td>
                    ) : (
                      <td className="text-center  text-xs whitespace-nowrap overflow-hidden ">
                        -
                      </td>
                    )} */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>
              <p>data is empty</p>
            </div>
          )}
        </div>
        {ismore && (
          <div className="px-3 py-1 bg-gray-400 text-white rounded-full mt-3  mb-3 self-center  cursor-pointer">
            <p onClick={() => clickmore()}>more</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;
