import * as React from "react";
import { Link } from "react-router-dom";
import { Turn as Hamburger } from "hamburger-react";
import { useMediaQuery } from "react-responsive";
import theme from "../styles/theme";
import logo from "../assets/logo.png";
import DeskMenu from "../component/Header/DeskMenu";

const Header = ({ navbarOpen, setNavbarOpen, isLoggedIn }) => {
  const Mobile = useMediaQuery({
    query: "(max-width: 1023px)",
  });

  React.useEffect(() => {}, []);

  return (
    <div className="sticky w-full z-10 bg-white shadow-sm top-0 m-auto">
      <header className="  m-auto ">
        <nav
          style={{ height: Mobile ? 50 : 60 }}
          className="col-span-4 lg:col-start-3 lg:col-end-11 px-3 lg:px-12 w-full flex flex-row items-center justify-between"
        >
          <Link
            to="/"
            onClick={() => setNavbarOpen(false)}
            className="flex flex-row cursor-pointer"
          >
            <img
              alt="mobilelogo"
              src={logo}
              className="cursor-pointer"
              style={{ width: 120, objectFit: "contain" }}
            />
          </Link>
          {Mobile ? (
            <Hamburger
              toggled={navbarOpen}
              toggle={setNavbarOpen}
              size={24}
              color={theme.GRAY_08}
            />
          ) : (
            <DeskMenu
              setNavbarOpen={setNavbarOpen}
              navbarOpen={navbarOpen}
              isLoggedIn={isLoggedIn}
            />
          )}
        </nav>
      </header>
    </div>
  );
};

export default Header;
