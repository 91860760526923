import React from "react";
import compaines from "../../component/Compaines";
import companies from "../../assets/companies.png";
import SectionTitle from "../../component/SectionTtitle/SectionTitle";
const Company = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const RenderContent = ({ name, website, subcontent, image }) => {
    return (
      <div
        className="col-span-10 lg:col-span-5  2xl:col-span-2 flex flex-col bg-white items-center justify-center 2xl:justify-start 2xl:items-center rounded-xl shadow-md relative p-6"
        style={{ height: 230 }}
      >
        <img
          alt={`${name}`}
          src={image}
          style={{ width: 200, height: 50, objectFit: "contain" }}
        />
        <span className="text-md  text-gray-600  text-center">{name}</span>
        {website && (
          <a
            href={website}
            target="_blank"
            rel="noreferrer"
            className=" bg-gray-400 text-white px-3 mt-3 rounded-full text-sm"
          >
            WEBSITE
          </a>
        )}
        <p className="text-md text-center mt-3 font-serif">{subcontent}</p>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-4 gap-3 lg:gap-12 w-full ">
      <SectionTitle image={companies} title="Companies that we have helped" />
      <div className="col-span-4 flex flex-col items-center justify-center mt-2 w-full p-3 lg:p-12">
        <p className="text-lg font-bold text-blue-800 text-justify">
          COMPANIES
        </p>
        <p className="text-md mt-3 text-justify text-black">
          We have helped various companies, from consumer product companies to
          technology service companies in different business sectors.
        </p>
      </div>
      <div className="col-span-4 grid grid-cols-10 gap-3 m-3">
        {compaines.map((item, index) => {
          if (item.subcontent) {
            return (
              <RenderContent
                key={index}
                image={item.image}
                name={item.name}
                website={item.website}
                subcontent={item.subcontent}
              />
            );
          } else {
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default Company;
