import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { BiChevronUp } from "react-icons/bi";
import Header from "../component/Header";
import MoblieMenu from "../component/Header/MobileMenu";
import Home from "../screen/Home/Home";
import Contact from "../component/Footer/Contact";
import Footer from "../component/Footer/Footer";
import About from "./About/About";
import Service from "./Service/Service";
import Project from "./Project/Project";
import Company from "./Company/Company";
import { useMediaQuery } from "react-responsive";
// import DeskMenu from "../component/Header/DeskMenu";
import Admin from "./Admin/Admin";
import AuthRoute from "../component/AuthRoute";
import firebase from "firebase";
import Login from "./Auth/Login";
import { logIn, logOut } from "../redux/userReducer";
import ResetPassWord from "./Auth/ResetPassWord";
import ScrollUpButton from "react-scroll-up-button";
const Gate = () => {
  let location = useLocation();
  let dispatch = useDispatch();
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  // const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const { isLoggedIn } = useSelector(state => state.userReducer);
  React.useEffect(() => {
    console.log(navbarOpen);
    if (navbarOpen) {
      window.scrollTo(0, 0);
    }
  }, [navbarOpen]);

  const Mobile = useMediaQuery({
    query: "(max-width: 1023px)",
  });

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        console.log("로그인");
        dispatch(logIn());
        // setIsLoggedIn(true);
      } else {
        // No user is signed in.
        console.log("로그아웃");
        dispatch(logOut());
        // setIsLoggedIn(false);
      }
    });
  }, [dispatch]);
  // console.log(location.pathname);
  return (
    <div className="relative w-full flex flex-col items-center font-sans bg-gray-100">
      <ToastContainer position="top-center" />
      {/* header */}
      <Header
        setNavbarOpen={setNavbarOpen}
        navbarOpen={navbarOpen}
        isLoggedIn={isLoggedIn}
      />
      {/* mobile menu */}
      <div
        className={`sticky flex flex-col items-start laptop:hidden bg-gray-100 w-full transition-all
          ${navbarOpen ? "h-58 opacity-100" : "h-0 opacity-0"}`}
      >
        {Mobile && (
          <MoblieMenu
            isLoggedIn={isLoggedIn}
            navbarOpen={navbarOpen}
            setNavbarOpen={setNavbarOpen}
          />
        )}
      </div>
      {/* section */}
      <div
        className={`w-full grid grid-cols-12 gap-3 lg:gap-12 pb-6  justify-center`}
      >
        <section className="col-span-12 lg:col-start-2 lg:col-end-12 xl:col-start-3 xl:col-end-11  flex flex-col items-center ">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/services" component={Service} />
            <Route exact path="/partners" component={Project} />
            <Route exact path="/companies" component={Company} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/reset" component={ResetPassWord} />
            <AuthRoute
              exact
              path="/admin"
              component={Admin}
              authenticated={isLoggedIn}
            />
          </Switch>
        </section>
        <section className="col-span-12 lg:col-start-2 lg:col-end-12 xl:col-start-3 xl:col-end-11  flex flex-col items-center ">
          {location.pathname !== "/admin" &&
            location.pathname !== "/login" &&
            location.pathname !== "/reset" && (
              <div id="contact" className="w-full">
                <Contact />
              </div>
            )}
          <Footer />
        </section>
        <ScrollUpButton
          ContainerClassName="AnyClassForContainer"
          TransitionClassName="AnyClassForTransition"
        >
          <div className="cursor-pointer">
            <BiChevronUp className="text-5xl text-white" />
          </div>
        </ScrollUpButton>
      </div>
    </div>
  );
};

export default Gate;
