import { createSlice } from "@reduxjs/toolkit";
// import { loadingStart, loadingEnd } from "./inProgressSlice";

import { toast } from "react-toastify";
import firebase from "firebase";

const userSlice = createSlice({
  name: "users",
  initialState: {
    isLoggedIn: false,
  },
  reducers: {
    logIn(state, action) {
      state.isLoggedIn = true;

      return state;
    },
    logOut(state, action) {
      state.isLoggedIn = false;

      return state;
    },
  },
});

export const { logIn, logOut } = userSlice.actions;

export const userLogin = ({ history, email, password }) => async dispatch => {
  try {
    // dispatch(loadingStart());
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        console.log(user);
        dispatch(logIn());
        history.push("/");
      })
      .catch(error => {
        var errorMessage = error.message;
        console.log(errorMessage);
      });
  } catch (e) {
    toast.error("아이디 또는 비밀번호가 잘못되었습니다.");
    // dispatch(loadingEnd());
    console.log(e);
  }
};
export const userLogout = history => async dispatch => {
  console.log("유저로그아웃");
  await firebase
    .auth()
    .signOut()
    .then(() => {
      // Sign-out successful.
      dispatch(logOut());
      history.push("/");
    })
    .catch(error => {
      // An error happened.
    });
};

export default userSlice.reducer;
