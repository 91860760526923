import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";
import development from "../../assets/services/development.png";
import investment from "../../assets/services/investment.png";
import legalsetup from "../../assets/services/legalsetup.png";
import shellcompanysetup from "../../assets/services/shellcompanysetup.png";
import workshop from "../../assets/services/workshop.png";
import services from "../../assets/services.png";
import SectionTitle from "../../component/SectionTtitle/SectionTitle";
const Service = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const RenderContent = ({ title, subcontent, image }) => {
    return (
      <div
        className="col-span-10 lg:col-span-5 flex flex-col bg-white items-center justify-center rounded-xl shadow-md relative p-6 2xl:p-24"
        style={{ minheight: 500 }}
      >
        <img
          alt={title}
          src={image}
          className="h-32 bg-center bg-cover bg-no-repeat object-contain"
        />
        <span className="text-md font-bold text-blue-800 tracking-widest text-center mt-4">
          {title}
        </span>
        <p className="text-md mt-3 font-serif text-justify">{subcontent}</p>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-4 gap-3 lg:gap-12 w-full">
      {/* 1 */}
      <SectionTitle
        image={services}
        title="Offering a range of strategic business development services"
      />

      {/* 2 */}
      <div className="col-span-4 flex flex-col items-center justify-center mt-2 w-full p-3 lg:p-12">
        <p className="text-lg font-bold text-blue-800 text-justify">
          OUR SERVICES
        </p>
        <p className="text-md mt-3 text-justify text-black">
          We offer a range of strategic development services to businesses of
          any size to assist in entering and navigating the US marketplace. We
          leverage our large network of consultants and partnerships in Seattle
          and Korea to provide a customized experience to suit our clients’
          needs. Click the links below to learn more about our offerings.
        </p>
      </div>
      <div className="col-span-4 grid grid-cols-10 gap-3 m-3 ">
        <RenderContent
          title="PRODUCT DEVELOPMENT"
          subcontent="We specialize in helping Korean companies develop, market, and launch their products in the United States. Our experts will help you create and sharpen product pitches and presentations, conduct market and user research, establish legal entities, and begin selling directly to American consumers. We also provide assistance with branding, packaging, and localization."
          image={development}
        />

        <RenderContent
          title="INVESTMENT PREPARATION & NETWORKING"
          subcontent="We help startups secure funding through our extensive partner network of investors and venture capital firms. We provide the necessary services to establish the proper legal entities in the United States. We also prepare our clients for investor meetings by reviewing and updating presentations to reflect trends and requirements in the U.S. market"
          image={investment}
        />
        <RenderContent
          title="SHELL COMPANY SETUP"
          subcontent="SeattleBase has extensive experience creating shell companies for large corporations to test products with an American audience. We provide branding, social media management, and talent acquisition of local professionals to make the transition to the U.S. market as seamless as possible. This allows for smaller teams within large companies to act as a true startup, providing valuable experience to those employees."
          image={shellcompanysetup}
        />
        <RenderContent
          title="WORKSHOPS & HACKATHONS"
          subcontent="We provide variety of interactive seminars. We run 2- to 6-week hackathons and onsite incubator programs for new product concepts. We offer a number of workshops to help businesses better understand the American marketplace. We also lead site visits to local Seattle companies, such as Amazon and Microsoft, to meet with executives and help our clients understand how they approach product development and strategy."
          image={workshop}
        />
        <RenderContent
          title="LEGAL BUSINESS ENTITY SETUP"
          subcontent="We provide all the necessary services for creating a legal entity in the United States, which is the first step in bringing international products to market. We guide our clients through the entire process, including structuring the company, working with attorneys, navigating local regulations, and acquiring and hiring talent and partners. We have extensive experience in transporting Korean business opportunities into U.S. business opportunities."
          image={legalsetup}
        />
      </div>

      <div className="col-span-4 flex flex-col items-center justify-center p-6 mt-3 w-full">
        <p className="">
          Our services are customized to fit the needs of our clients. Please
          get in touch with us so that we may address your specific business
          requirements.
        </p>
        <AnchorLink
          offset="70"
          href="#contact"
          className="outline-none right-1 bg-blue-800 text-white px-4 py-2 rounded-full flex flex-row items-center justify-between text-xs mt-6"
        >
          CONTACT US
        </AnchorLink>
      </div>
      <div className="col-span-4 flex flex-col items-center justify-center p-6 mt-3 w-full ">
        <p className="text-lg font-bold text-blue-800">
          PROJECTS & PARTNERSHIPS
        </p>
        <p className="mt-3">
          Follow the link to learn more about the partners we work with and
          review some of the projects we’ve completed.
        </p>
        <Link
          to="/partners"
          className="outline-none right-1 bg-blue-800 text-white px-4 py-2 rounded-full flex flex-row items-center justify-between text-xs mt-6"
        >
          VIEW OUR PAST PROJECTS
        </Link>
      </div>
    </div>
  );
};

export default Service;
