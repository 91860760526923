import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyDT5_ig0tSMuvfWK3E1Tmf0HgHFmaMfTu4",
  authDomain: "seattlebase-3c827.firebaseapp.com",
  projectId: "seattlebase-3c827",
  storageBucket: "seattlebase-3c827.appspot.com",
  messagingSenderId: "984654710320",
  appId: "1:984654710320:web:70121243af41acb8c4cff5",
  measurementId: "G-YZREHCFW5W",
};

// firebaseConfig 정보로 firebase 시작
firebase.initializeApp(firebaseConfig);

// firebase의 firestore 인스턴스를 변수에 저장
const firestore = firebase.firestore();
const storageRef = firebase.storage();

// 필요한 곳에서 사용할 수 있도록 내보내기
export { firestore, storageRef, firebaseConfig };
