import React from "react";

const SectionTitle = ({ image, title }) => {
  return (
    <div
      style={{ backgroundImage: `url(${image})`, minHeight: 450 }}
      className="relative col-span-4 flex flex-col bg-white items-center justify-center lg:rounded-2xl lg:mt-3 overflow-hidden shadow-md bg-cover bg-no-repeat object-contain"
    >
      <div className="p-6 lg:p-12 flex flex-col items-start lg:items-center justify-center">
        <p className="text-3xl font-bold text-white tracking-wider">{title}</p>
      </div>
    </div>
  );
};

export default SectionTitle;
