import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "firebase";
import { Link } from "react-router-dom";

const Login = () => {
  let history = useHistory();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const clickSum = async () => {
    // firebase create user
    console.log("로그인");
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        // Signed in
        // ...
        console.log(user);
        history.push("/");
      })
      .catch(error => {
        // console.log(error);
        toast.error(error.message);
      });
  };
  // const passwordReset = async email => {
  //   await firebase
  //     .auth()
  //     .sendPasswordResetEmail(email)
  //     .then(result => console.log(result))
  //     .catch(error => {
  //       toast.error(error.message);
  //     });
  // };
  return (
    <div className="w-full p-3 ">
      {/* 1 */}
      <div className="mt-3 w-full flex flex-col items-center justify-between p-6 bg-white rounded-xl">
        <span className="text-md lg:text-2xl font-bold text-blue-800">
          ADMIN
        </span>
        <div className="w-full lg:w-1/3 mt-3">
          <div className="flex flex-col items-start  w-full">
            <label>
              <span className="text-gray-500">EMAIL</span>
            </label>
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="px-3 bg-gray-200 py-1 rounded-md w-full"
            />
          </div>
          <div className="flex flex-col items-start mt-3">
            <label>
              <span className="text-gray-500">PASSWORD</span>
            </label>
            <input
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              className="px-3 bg-gray-200 py-1 rounded-md  w-full"
            />
          </div>
        </div>
        <button
          className="bg-blue-800 px-4 py-1 rounded-full mt-4"
          onClick={() => clickSum()}
        >
          <p className="text-md text-white">SIGN IN</p>
        </button>
        <Link className="mt-4" to="/reset">
          <p className="text-md text-gray-500">reset password</p>
        </Link>
      </div>
    </div>
  );
};

export default Login;
