import React from "react";
import { Link, NavLink } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
const DeskMenu = ({ setNavbarOpen, navbarOpen, isLoggedIn }) => {
  return (
    <ul className="flex  h-full flex-row  items-center p-3 relative">
      <NavLink
        to={"/about"}
        onClick={() => setNavbarOpen(false)}
        className="text-black px-3 py-1  font-thin flex flex-row items-center justify-center"
        activeClassName="font-bold"
      >
        {/* <IoEllipseSharp size={8} /> */}
        <p className="ml-2">ABOUT</p>
      </NavLink>

      <NavLink
        to={"/services"}
        onClick={() => setNavbarOpen(false)}
        className="text-black  px-3 py-1  font-thin flex flex-row items-center justify-center"
        activeClassName="font-bold"
      >
        <p className="ml-2">SERVICES</p>
      </NavLink>

      <NavLink
        onClick={() => setNavbarOpen(false)}
        to={"/partners"}
        className=" text-black  px-3 py-1  font-thin flex flex-row items-center justify-center"
        activeClassName="font-bold"
      >
        <p className="ml-2">PARTNERS</p>
      </NavLink>
      <NavLink
        onClick={() => setNavbarOpen(false)}
        to={"/companies"}
        className=" text-black  px-3 py-1  font-thin flex flex-row items-center justify-center"
        activeClassName="font-bold"
      >
        <p className="ml-2">COMPANIES</p>
      </NavLink>
      {isLoggedIn ? (
        <NavLink
          onClick={() => setNavbarOpen(false)}
          to={"/admin"}
          className=" text-black  px-3 py-1  font-thin flex flex-row items-center justify-center"
          activeClassName="font-bold"
        >
          <p className="ml-2">ADMIN</p>
        </NavLink>
      ) : (
        <AnchorLink
          onClick={() => setNavbarOpen(false)}
          href="#contact"
          offset="250"
          className=" text-black px-3 py-1  font-thin flex flex-row items-center justify-center"
        >
          <p className="ml-2">CONTACT</p>
        </AnchorLink>
      )}
      <div className="flex flex-row items-center ml-2">
        <a
          onClick={() => setNavbarOpen(false)}
          href="https://www.facebook.com/seattlebase"
          target="_blank"
          rel="noreferrer"
        >
          <AiFillFacebook className="text-2xl" />
        </a>
        <a
          onClick={() => setNavbarOpen(false)}
          href="https://www.linkedin.com/company/seattlebase/"
          target="_blank"
          rel="noreferrer"
        >
          <AiFillLinkedin className=" ml-3 text-2xl" />
        </a>
      </div>
    </ul>
  );
};

export default DeskMenu;
