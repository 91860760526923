import naturalJ from "../assets/companies/maturalj.png";
import morai from "../assets/companies/morai.png";
import drtail from "../assets/companies/drtail.png";
import coofen from "../assets/companies/coofen.png";
import goodbyecar from "../assets/companies/goodbyecar.png";
import moneybrain from "../assets/companies/moneybrain.png";
import myungjikorea from "../assets/companies/myungjikorea.png";
import delightpool from "../assets/companies/delightpool.png";
import omorobot from "../assets/companies/omorobot.png";
import waynehillsai from "../assets/companies/waynehillsai.png";
import livingjin from "../assets/companies/nooria.png";
import ineco from "../assets/companies/ineco.png";
import cmes from "../assets/companies/cmes.png";
import twoytech from "../assets/companies/dogreen.png";
import twinny from "../assets/companies/txinny.png";
import probus from "../assets/companies/probus.png";
import pinafore from "../assets/companies/piuafane.png";
import hrobotics from "../assets/companies/hrobotics.png";
const companies = [
  {
    image: naturalJ,
    name: "Natural J",
    website: null,
    subcontent: "Nano-coating technology cosmetic products",
  },
  {
    image: morai,
    name: "MORAI",
    website: "https://www.morai.ai/",
    subcontent: "True-to-life Autonomous Vehicle Driving Simulator",
  },
  {
    image: drtail,
    name: "Dr.Tail Inc.",
    website: "https://drtail.us/",
    subcontent: "Veterinary Consultation Platform",
  },
  {
    image: coofen,
    name: "Coofen",
    website: "http://www.coofenusa.com/",
    subcontent: "Eco-friendly frying pan",
  },
  {
    image: goodbyecar,
    name: "GOODBYECAR",
    website: "http://bastro.net/eng/",
    subcontent: "Portable Power Station - Electric Vehicle Battery Upcycling",
  },
  {
    image: moneybrain,
    name: "MoneyBrain",
    website: "http://moneybrain.ai/",
    subcontent: "AI for Human - Video Synthesis Solution",
  },
  {
    image: myungjikorea,
    name: "MyungJiKorea",
    website: "http://www.argokr.com/",
    subcontent: "Interactive AR game kiosk",
  },
  {
    image: delightpool,
    name: "DELIGHTPOOL",
    website: "http://delightpool.com/",
    subcontent: "Stylish, women's athleisure swimwear",
  },
  {
    image: omorobot,
    name: "OMOROBOT INC",
    website: "https://www.omorobot.com",
    subcontent: "Autonomous robots for people",
  },
  {
    image: waynehillsai,
    name: "Waynehills Ventures Inc.",
    website: "https://www.waynehills.co/",
    subcontent: "Text to Video AI & Speech to Video AI",
  },
  {
    image: livingjin,
    name: "LIVING JIN",
    website: "https://livingjin.com/",
    subcontent: "Agar Agar Powder",
  },
  {
    image: ineco,
    name: "INECO",
    website: "http://en.in-eco.co.kr/",
    subcontent: "Eco-friendly construction material",
  },
  {
    image: cmes,
    name: "CMES",
    website: "https://www.cmes3d.com/",
    subcontent: "AI-Powered 3D Robot Vision",
  },
  {
    image: twoytech,
    name: "TowYTech",
    website: "http://twoytech.com/page/doc.php?m_id=123",
    subcontent: "Gardening tools",
  },
  {
    image: twinny,
    name: "TWINNY",
    website: "https://twinny.ai/home",
    subcontent: "Autonomous Mobile Robot & Robot Platform",
  },
  {
    image: probus,
    name: "PROBUS Medical Technologies",
    website: "http://www.probusmedtech.com/",
    subcontent: "Connecting Blood to the Internet --- Painlessly",
  },
  {
    image: pinafore,
    name: "Pinafore",
    website: "https://pinafore.studio/",
    subcontent: "content-driven baking kit service",
  },
  {
    image: hrobotics,
    name: "H Robotics Inc",
    website: "https://hroboticsinc.com/",
    subcontent: "Smart Tele-Rehab Solution",
  },
];

export default companies;
